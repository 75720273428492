import React from 'react'
import { useState } from 'react';
import { navigate } from "gatsby";

function Navigation({data, locale}) {
    const [slug, setSlug] = useState("");
    const handleClick = (val) => {
        setSlug(val);
        navigate(`/technologies/#${val}`, { replace: true })
    }

    React.useEffect(() => {

        let selectedSlug = window?.location.href.split('#')[1]
        if(selectedSlug) {
            setSlug(selectedSlug);
        } else {
            navigate(`/technologies/#${data.l1.slug}`, { replace: true });
        }
    });

    return (
            <div className="pageNav technoPage">
                <ul className="pageNav-ul">
                    <li key={1} onClick={(e) => handleClick(data.l1.slug)} className={slug === data.l1.slug ? 'active':''}>{data.l1.label}</li>
                    <li key={2} onClick={(e) => handleClick(data.l2.slug)} className={slug === data.l2.slug ? 'active':''}>{data.l2.label}</li>
                    <li key={3} onClick={(e) => handleClick(data.l3.slug)} className={slug === data.l3.slug ? 'active':''}>{data.l3.label}</li>
                    <li key={4} onClick={(e) => handleClick(data.l4.slug)} className={slug === data.l4.slug ? 'active':''}>{data.l4.label}</li>
                    <li key={5} onClick={(e) => handleClick(data.l5.slug)} className={slug === data.l5.slug ? 'active':''}>{data.l5.label}</li>
                    <li key={6} onClick={(e) => handleClick(data.l6.slug)} className={slug === data.l6.slug ? 'active':''}>{data.l6.label}</li>

                    {/* <li><a href="#internet-of-things">{data.l1}</a></li>
                    <li><a href="#ai-ml">{data.l2}</a></li>
                    <li><a href="#business-insights">{data.l3}</a></li>
                    <li><a href="#containerization">{data.l4}</a></li>
                    <li><a href="#database">{data.l5}</a></li>
                    <li><a href="#microservices">{data.l6}</a></li> */}
                    {/* <li><a href="#opsio-AI">{data.l7}</a></li> */}
                </ul>
            </div>
    )
}

export default Navigation
