import React from 'react';
import AI from '../../../images/AI.svg';
import { Link } from 'gatsby';

function About({ opsioAI, locale }) {
    return (
        <div id="opsio-AI" className="about">
            <div className="containerwrap">
                <div className="aboutTitle">{opsioAI.title}</div>
                <div className="aboutHeading">
                    {opsioAI.heading}
                </div>
                <div className="brainimage-mobile">
                    <img src={AI} alt='brain-image' />
                </div>
                <div className="aboutDescription">
                    {opsioAI.des.map((val, index) => <div className="aboutDescriptionLeft" key={index}>
                        {val} </div>)}
                </div>
                <div className="aboutOptions">
                    {opsioAI.service.map((aboutColumn, index) => {
                        return (
                            <div className="aboutOptionsColumn" key={index}>
                                <div className="aboutOptionsData">{aboutColumn}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="aboutEnquireNow">
                <div className="aboutEnquireNowHeading">{opsioAI.note}</div>
                <Link to={`/contact-us/`}>
                    <button className="aboutEnquireNowBtn">{opsioAI.btn}</button>
                </Link>
            </div>
        </div>
    )
}

export default About
