import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { navigate } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AI from "../../../images/AI.svg";
import icon from "../../../images/ai-icon.svg";
import { Link } from "gatsby";

function Overview({ overview, opsioAI, locale }) {
  return (
    <>
      <div className="overviewSolutions technology_upper_class">
        {overview.strapi_json_value.map((val, index) => (
          <VisibilityWrap sectionId={val?.id}>
            <div
              className="containerwrap techcontainer scrollHotspot"
              id={val.id}
              key={index}
            >
              <div className="overviewSectionSolutions">
                <div className="overviewTitleSolutions">{val.title}</div>
                <h2 className="overviewHeadingSolutions pb-5">{val.heading}</h2>
              </div>
              <div className="overviewContentSolutions">
                {val.des.map((val, index) => (
                  <div className="overviewContentLeftSolutions" key={index}>
                    {val}
                  </div>
                ))}
              </div>

              <div id="opsio-AI" className="about">
                <div
                  className={`containerwrap ${
                    index == 1 ? "containerwrap2" : ""
                  }`}
                >
                  <div className="aboutTitle">
                    {opsioAI.strapi_json_value[index].title}
                  </div>
                  <h2 className="aboutHeading">
                    {opsioAI.strapi_json_value[index].heading}
                  </h2>
                  <div className="brainimage-mobile">
                    {index == 1 ? (
                      <img src={AI} alt="brain-image" />
                    ) : (
                      <img src={icon} alt="brain-image" />
                    )}
                  </div>
                  <div className="aboutDescription">
                    {opsioAI.strapi_json_value[index].des.map((val, index) => (
                      <div className="aboutDescriptionLeft" key={index}>
                        {val}{" "}
                      </div>
                    ))}
                  </div>
                  <div className="aboutOptions">
                    {opsioAI.strapi_json_value[index].service.map(
                      (aboutColumn, index) => {
                        return (
                          <div className="aboutOptionsColumn" key={index}>
                            <div className="aboutOptionsData">
                              {aboutColumn}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="aboutEnquireNow">
                  <div className="aboutEnquireNowHeading">
                    {opsioAI.strapi_json_value[index].note}
                  </div>
                  <div className="opsBtnwrap">
                    <Link className="opsBtnlink" to={`/contact-us/`}>
                      <p>{opsioAI.strapi_json_value[index].btn}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </VisibilityWrap>
        ))}
      </div>
    </>
  );
}

const VisibilityWrap = ({ sectionId, children }) => {
  const [active, setActive] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  setTimeout(() => {
    setActive(true);
  }, 1200);

  useEffect(() => {
    const handler = () => {
      setActive(false);
      setTimeout(() => {
        setActive(true);
      }, 1200);
    };

    setScreenHeight(window.innerHeight);

    const upperClass = document.getElementsByClassName(
      "technology_upper_class"
    )[0];

    upperClass?.addEventListener("click", handler);

    return () => {
      upperClass?.removeEventListener("click", handler);
    };
  });

  return (
    <VisibilitySensor
      active={active}
      partialVisibility={true}
      delayedCall={true}
      offset={{
        top: screenHeight > 0 ? screenHeight / 2 : 300,
        bottom: screenHeight > 0 ? screenHeight / 2 : 300,
      }}
      onChange={(isVisible) => {
        if (isVisible) {
          navigate(`/technologies/#${sectionId}`);
        }
      }}
    >
      {children}
    </VisibilitySensor>
  );
};

export default Overview;
